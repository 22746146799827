const home = () => import(/* webpackChunkName: "home" */ '@/views/management/home.vue')
const studentManagement = () => import(/* webpackChunkName: "studentManagement" */ '@/views/management/studentManagement.vue')
const studentManagementAdd = () => import(/* webpackChunkName: "studentManagementAdd" */ '@/views/management/studentManagementAdd.vue')
const studentManagementEdit = () => import(/* webpackChunkName: "studentManagementEdit" */ '@/views/management/studentManagementEdit.vue')

// const campusScreening = () => import(/* webpackChunkName: "campusScreening" */ '@/views/management/campusScreening.vue')
const teacherManage = () => import(/* webpackChunkName: "teacherManage" */ '@/views/management/teacherManage.vue')
const teacherManageAdd = () => import(/* webpackChunkName: "teacherManageAdd" */ '@/views/management/teacherManageAdd.vue')
const teacherManageEdit = () => import(/* webpackChunkName: "teacherManageEdit" */ '@/views/management/teacherManageEdit.vue')
const organization = () => import(/* webpackChunkName: "organization" */ '@/views/management/organization.vue')
const organizationAdd = () => import(/* webpackChunkName: "organizationAdd" */ '@/views/management/organizationAdd.vue')
const organizationEdit = () => import(/* webpackChunkName: "organizationEdit" */ '@/views/management/organizationEdit.vue')
const government = () => import(/* webpackChunkName: "government" */ '@/views/management/government.vue')
const governmentEdit = () => import(/* webpackChunkName: "governmentEdit" */ '@/views/management/governmentEdit.vue')
const governmentAdd = () => import(/* webpackChunkName: "governmentAdd" */ '@/views/management/governmentAdd.vue')
const school = () => import(/* webpackChunkName: "school" */ '@/views/management/school.vue')
const schoolAdd = () => import(/* webpackChunkName: "schoolAdd" */ '@/views/management/schoolAdd.vue')
const schoolEdit = () => import(/* webpackChunkName: "schoolEdit" */ '@/views/management/schoolEdit.vue')
const schoolClass = () => import(/* webpackChunkName: "schoolClass" */ '@/views/management/schoolClass.vue')
const schoolClassAdd = () => import(/* webpackChunkName: "schoolClassAdd" */ '@/views/management/schoolClassAdd.vue')
const schoolClassEdit = () => import(/* webpackChunkName: "schoolClassEdit" */ '@/views/management/schoolClassEdit.vue')
const teacher = () => import(/* webpackChunkName: "teacher" */ '@/views/management/teacher.vue')
const teacherAdd = () => import(/* webpackChunkName: "teacherAdd" */ '@/views/management/teacherAdd.vue')
const teacherEdit = () => import(/* webpackChunkName: "teacherEdit" */ '@/views/management/teacherEdit.vue')
const schoolGrade = () => import(/* webpackChunkName: "grade" */ '@/views/management/grade.vue')
const schoolGradeAdd = () => import(/* webpackChunkName: "gradeAdd" */ '@/views/management/gradeAdd.vue')
const schoolGradeEdit = () => import(/* webpackChunkName: "gradeEdit" */ '@/views/management/gradeEdit.vue')
const record = () => import(/* webpackChunkName: "record" */ '@/views/management/record.vue')
const recordEdit = () => import(/* webpackChunkName: "recordEdit" */ '@/views/management/recordEdit.vue')
const messageCenter = () => import(/* webpackChunkName: "messageCenter" */ '@/views/management/messageCenter.vue')
const schoolGradeManagement = () => import(/* webpackChunkName: "schoolGradeManagement" */ '@/views/management/schoolGradeManagement.vue')
const schoolGradeManagementAdd = () => import(/* webpackChunkName: "schoolGradeManagementAdd" */ '@/views/management/schoolGradeManagementAdd.vue')
const schoolGradeManagementEdit = () => import(/* webpackChunkName: "schoolGradeManagementEdit" */ '@/views/management/schoolGradeManagementEdit.vue')
const schoolClassManagement = () => import(/* webpackChunkName: "schoolClassManagement" */ '@/views/management/schoolClassManagement.vue')
const schoolClassManagementAdd = () => import(/* webpackChunkName: "schoolClassManagementAdd" */ '@/views/management/schoolClassManagementAdd.vue')
const schoolClassManagementEdit = () => import(/* webpackChunkName: "schoolClassManagementEdit" */ '@/views/management/schoolClassManagementEdit.vue')
const historicalData = () => import(/* webpackChunkName: "historicalData" */ '@/views/management/historicalData.vue')
// 班级批量导入
const classStudentsImport = () => import(/* webpackChunkName: "classStudentsImport" */ '@/views/management/classStudentsImport.vue')
// 学校批量导入
const schoolStudentsImport = () => import(/* webpackChunkName: "schoolStudentsImport" */ '@/views/management/schoolStudentsImport.vue')
// 筛查计划批量导入
const importScreeningData = () => import(/* webpackChunkName: "importScreeningData" */ '@/views/management/importScreeningData.vue')
// 筛选卡拉玛版
const importKaramayEdition = () => import(/* webpackChunkName: "importKaramayEdition" */ '@/views/management/importKaramayEdition.vue')
// 政府
const homeGovernment = () => import(/* webpackChunkName: "homeGovernment" */ '@/components/managementLocalComponents/home/homeGovernment.vue')
// 机构
const homeOrganization = () => import(/* webpackChunkName: "homeOrganization" */ '@/components/managementLocalComponents/home/homeOrganization.vue')
// 学校
const homeSchool = () => import(/* webpackChunkName: "homeSchool" */ '@/components/managementLocalComponents/home/homeSchool.vue')
// 班级
const homeGrade = () => import(/* webpackChunkName: "homeGrade" */ '@/components/managementLocalComponents/home/homeGrade.vue')
// 校级管理员
const schoolAdministrator = () => import(/* webpackChunkName: "schoolAdministrator" */ '@/views/management/schoolAdministrator.vue')
const schoolAdministratorAdd = () => import(/* webpackChunkName: "schoolAdministratorAdd" */ '@/views/management/schoolAdministratorAdd.vue')
const schoolAdministratorEdit = () => import(/* webpackChunkName: "schoolAdministratorEdit" */ '@/views/management/schoolAdministratorEdit.vue')
// 筛查前设置
const schoolSet = () => import(/* webpackChunkName: "schoolSet" */ '@/views/management/schoolSet.vue')
// 侧式历史
const studentTesthistory = () => import(/* webpackChunkName: "studentTesthistory" */ '@/views/management/studentTesthistory.vue')

// 筛查
const AnalysisChart = () => import(/* webpackChunkName: "AnalysisChart" */ '@/views/detection/AnalysisChart.vue')
const SchoolReport = () => import(/* webpackChunkName: "SchoolReport" */ '@/views/detection/SchoolReport.vue')
const ScreeningListQuery = () => import(/* webpackChunkName: "ScreeningListQuery" */ '@/views/detection/ScreeningListQuery.vue')
const barCode = () => import(/* webpackChunkName: "barCode" */ '@/views/detection/barCode.vue')
const ScreeningPlanList = () => import(/* webpackChunkName: "ScreeningPlanList" */ '@/views/detection/ScreeningPlanList.vue')
const ScreeningPlanAdd = () => import(/* webpackChunkName: "ScreeningPlanAdd" */ '@/views/detection/ScreeningPlanAdd.vue')
const ScreeningPlanEdit = () => import(/* webpackChunkName: "ScreeningPlanEdit" */ '@/views/detection/ScreeningPlanEdit.vue')
const SchoolList = () => import(/* webpackChunkName: "SchoolList" */ '@/views/detection/SchoolList.vue')
const Student = () => import(/* webpackChunkName: "Student" */ '@/views/detection/Student.vue')
const Class = () => import(/* webpackChunkName: "Class" */ '@/views/detection/Class.vue')
const ImportStudent = () => import(/* webpackChunkName: "ImportStudent" */ '@/views/detection/ImportStudent.vue')
const Recycle = () => import(/* webpackChunkName: "Recycle" */ '@/views/detection/Recycle.vue')
const AddStudentInfo = () => import(/* webpackChunkName: "AddStudentInfo" */ '@/views/detection/AddStudentInfo.vue')
const EditStudentInfo = () => import(/* webpackChunkName: "EditStudentInfo" */ '@/views/detection/EditStudentInfo.vue')
const SchoolManagement = () => import(/* webpackChunkName: "SchoolManagement" */ '@/views/detection/SchoolManagement.vue')
const SchoolAdd = () => import(/* webpackChunkName: "SchoolAdd" */ '@/views/detection/SchoolAdd.vue')
const SchoolEdit = () => import(/* webpackChunkName: "SchoolEdit" */ '@/views/detection/SchoolEdit.vue')
const AccountManagement = () => import(/* webpackChunkName: "AccountManagement" */ '@/views/detection/AccountManagement.vue')
const AccountAdd = () => import(/* webpackChunkName: "AccountAdd" */ '@/views/detection/AccountAdd.vue')
const AccountEdit = () => import(/* webpackChunkName: "AccountEdit" */ '@/views/detection/AccountEdit.vue')

const MonitoringKanban = () => import(/* webpackChunkName: "monitoringKanban" */ '@/views/detection/monitoringKanban.vue')
const MonitoringManagement = () => import(/* webpackChunkName: "monitoringManagement" */ '@/views/detection/monitoringManagement.vue')
// const MonitoringDynaport = () => import(/* webpackChunkName: "monitoringDynaport" */ '@/views/detection/monitoringDynaport.vue')

export default [
  {
    path: '/management/home',
    name: 'managementHome',
    component: home,
    meta: {
      nav: {
        title: '首页',
        icon: 'el-icon-s-home',
        desc: '首页',
        jurisdiction: [6, 10, 7, 99, 9]
      }
    }
  },
  {
    path: '/management/home/show',
    name: 'managementHomeShow',
    component: { render: h => h('router-view') },
    meta: {
      nav: {
        desc: '首页',
        jurisdiction: [6, 10, 7, 99, 9]
      }
    },
    children: [
      {
        path: '/management/home/Government',
        name: 'managementHomeGovernment',
        component: homeGovernment,
        meta: {
          nav: {
            desc: '政府',
            icon: 'el-icon-user-solid',
            jurisdiction: [99]
          }
        }
      },
      {
        path: '/management/home/Organization',
        name: 'managementHomeOrganization',
        component: homeOrganization,
        meta: {
          nav: {
            desc: '机构',
            icon: 'el-icon-user-solid',
            jurisdiction: [9]
          }
        }
      },
      {
        path: '/management/home/School',
        name: 'managementHomeSchool',
        component: homeSchool,
        meta: {
          nav: {
            desc: '学校',
            icon: 'el-icon-user-solid',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/home/Grade',
        name: 'managementHomeGrade',
        component: homeGrade,
        meta: {
          nav: {
            desc: '班级',
            icon: 'el-icon-user-solid',
            jurisdiction: [7]
          }
        }
      }
    ]
  },
  {
    path: '/management/user',
    name: 'managementUser',
    component: { render: h => h('router-view') },
    meta: {
      nav: {
        title: '用户管理',
        desc: '用户管理',
        icon: 'el-icon-user-solid',
        jurisdiction: [1]
      }
    },
    children: [
      {
        path: '/management/organization',
        name: 'managementOrganization',
        component: organization,
        meta: {
          nav: {
            title: '机构管理',
            desc: '机构管理',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/organization/add',
        name: 'managementOrganizationAdd',
        component: organizationAdd,
        meta: {
          nav: {
            desc: '机构添加',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/organization/edit',
        name: 'managementOrganizationEdit',
        component: organizationEdit,
        meta: {
          nav: {
            desc: '机构编辑',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/government',
        name: 'managementGovernment',
        component: government,
        meta: {
          nav: {
            title: '政府管理',
            desc: '政府管理',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/government/add',
        name: 'managementGovernmentAdd',
        component: governmentAdd,
        meta: {
          nav: {
            desc: '政府添加',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/government/edit',
        name: 'managementGovernmentEdit',
        component: governmentEdit,
        meta: {
          nav: {
            desc: '政府编辑',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school',
        name: 'managementSchool',
        component: school,
        meta: {
          nav: {
            title: '学校管理',
            desc: '学校管理',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school/add',
        name: 'managementSchoolAdd',
        component: schoolAdd,
        meta: {
          nav: {
            desc: '学校添加',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school/edit',
        name: 'managementSchoolEdit',
        component: schoolEdit,
        meta: {
          nav: {
            desc: '学校编辑',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/teacher',
        name: 'managementTeacher',
        component: teacher,
        meta: {
          nav: {
            // title: '教师管理',
            desc: '教师管理',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/teacher/add',
        name: 'managementTeacherAdd',
        component: teacherAdd,
        meta: {
          nav: {
            desc: '教师添加',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/teacher/edit',
        name: 'managementTeacherEdit',
        component: teacherEdit,
        meta: {
          nav: {
            desc: '教师编辑',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school/grade',
        name: 'managementSchoolGrade',
        component: schoolGrade,
        meta: {
          nav: {
            desc: '年级管理',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school/grade/add',
        name: 'managementSchoolGradeAdd',
        component: schoolGradeAdd,
        meta: {
          nav: {
            desc: '年级添加',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school/grade/edit',
        name: 'managementSchoolGradeEdit',
        component: schoolGradeEdit,
        meta: {
          nav: {
            desc: '年级编辑',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school/class',
        name: 'managementSchoolClass',
        component: schoolClass,
        meta: {
          nav: {
            desc: '班级管理',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school/class/add',
        name: 'managementSchoolClassAdd',
        component: schoolClassAdd,
        meta: {
          nav: {
            desc: '班级添加',
            jurisdiction: [1]
          }
        }
      },
      {
        path: '/management/school/class/edit',
        name: 'managementSchoolClassEdit',
        component: schoolClassEdit,
        meta: {
          nav: {
            desc: '班级编辑',
            jurisdiction: [1]
          }
        }
      }
    ]
  },
  {
    path: '/management/record',
    name: 'managementRecord',
    component: record,
    meta: {
      nav: {
        title: '档案管理',
        desc: '档案管理',
        jurisdiction: [1],
        icon: 'el-icon-s-order'

      }
    }
  },
  {
    path: '/management/record/edit',
    name: 'managementRecordEdit',
    component: recordEdit,
    meta: {
      nav: {
        desc: '档案修改',
        jurisdiction: [1],
        icon: 'el-icon-s-order'

      }
    }
  },
  {
    path: '/management/messageCenter',
    name: 'managementMessageCenter',
    component: messageCenter,
    meta: {
      nav: {
        title: '消息中心',
        desc: '消息中心',
        jurisdiction: [1],
        icon: 'el-icon-s-promotion'

      }
    }
  },
  {
    path: '/management/school/management',
    name: 'managemenSchoolManagement',
    component: { render: h => h('router-view') },
    meta: {
      nav: {
        title: '校园管理',
        desc: '校园管理',
        icon: 'el-icon-s-custom',
        jurisdiction: [6, 10, 7]
      }
    },
    children: [
      {
        path: '/management/teacher/manage',
        name: 'managementTeacherManage',
        component: teacherManage,
        meta: {
          nav: {
            title: '教师管理',
            desc: '教师管理',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/teacher/manage/add',
        name: 'managementTeacherManageAdd',
        component: teacherManageAdd,
        meta: {
          nav: {
            desc: '教师添加',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/teacher/manage/edit',
        name: 'managementTeacherManageEdit',
        component: teacherManageEdit,
        meta: {
          nav: {
            desc: '教师编辑',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/Management',
        name: 'managementStudentManagement',
        component: studentManagement,
        meta: {
          nav: {
            title: '学生管理',
            desc: '学生管理',
            jurisdiction: [6, 10, 7]
          }
        }
      },
      {
        path: '/management/student/Management/add',
        name: 'managementStudentManagementAdd',
        component: studentManagementAdd,
        meta: {
          nav: {
            desc: '学生添加',
            jurisdiction: [6, 10, 7]
          }
        }
      },
      {
        path: '/management/student/Management/edit',
        name: 'managementStudentManagementEdit',
        component: studentManagementEdit,
        meta: {
          nav: {
            desc: '学生编辑',
            jurisdiction: [6, 10, 7]
          }
        }
      },
      {
        path: '/management/student/testhistory',
        name: 'managementStudentTesthistory',
        component: studentTesthistory,
        meta: {
          nav: {
            // title: '学生管理',
            desc: '测试历史',
            jurisdiction: [6, 10, 7, 1]
          }
        }
      },
      {
        path: '/management/student/class/Students/import',
        name: 'managementStudenClassStudentsImport',
        component: classStudentsImport,
        meta: {
          nav: {
            desc: '班级学生导入',
            jurisdiction: [7]
          }
        }
      },
      {
        path: '/management/student/scholl/Students/import',
        name: 'managementStudenSchollStudentsImport',
        component: schoolStudentsImport,
        meta: {
          nav: {
            desc: '学校学生导入',
            jurisdiction: [6, 10]
          }
        }

      },
      {
        path: '/management/student/school/grade/management',
        name: 'managementStudentSchoolGradeManagement',
        component: schoolGradeManagement,
        meta: {
          nav: {
            title: '年级管理',
            desc: '年级管理',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/school/grade/management/add',
        name: 'managementStudentSchoolGradeManagementAdd',
        component: schoolGradeManagementAdd,
        meta: {
          nav: {
            desc: '年级添加',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/school/grade/management/edit',
        name: 'managementStudentSchoolGradeManagementEdit',
        component: schoolGradeManagementEdit,
        meta: {
          nav: {
            desc: '年级编辑',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/school/class/management',
        name: 'managementStudentSchoolClassManagement',
        component: schoolClassManagement,
        meta: {
          nav: {
            desc: '班级管理',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/school/class/management/add',
        name: 'managementStudentSchoolClassManagementAdd',
        component: schoolClassManagementAdd,
        meta: {
          nav: {
            desc: '班级添加',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/school/class/management/edit',
        name: 'managementStudentSchoolClassManagementEdit',
        component: schoolClassManagementEdit,
        meta: {
          nav: {
            desc: '班级编辑',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/school/historicalData',
        name: 'managementStudentSchoolhistoricalData',
        component: historicalData,
        meta: {
          nav: {
            title: '历史数据',
            desc: '历史数据',
            jurisdiction: [6, 10, 7]
          }
        }
      },
      {
        path: '/management/student/school/schoolAdministrator',
        name: 'managementStudentSchoolSchoolAdministrator',
        component: schoolAdministrator,
        meta: {
          nav: {
            title: '校级管理员',
            desc: '校级管理员',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/school/schoolAdministrator/add',
        name: 'managementStudentSchoolSchoolAdministratorAdd',
        component: schoolAdministratorAdd,
        meta: {
          nav: {
            desc: '添加',
            jurisdiction: [6, 10]
          }
        }
      },
      {
        path: '/management/student/school/schoolAdministrator/edit',
        name: 'managementStudentSchoolSchoolAdministratorEdit',
        component: schoolAdministratorEdit,
        meta: {
          nav: {
            desc: '编辑',
            jurisdiction: [6, 10]
          }
        }
      }
    ]
  },
  // {
  //   path: '/management/campus/screening',
  //   name: 'managementCampusScreening',
  //   component: { render: h => h('router-view') },
  //   meta: {
  //     nav: {
  //       title: '校园筛查',
  //       desc: '校园筛查',
  //       icon: 'el-icon-school',
  //       jurisdiction: [6, 10, 9]
  //     }
  //   },
  //   children: [
  //     {
  //       path: '/management/campus/screening',
  //       name: 'managementCampusScreening',
  //       redirect: '/detection/analysisChart',
  //       meta: {
  //         nav: {
  //           title: '校园筛查',
  //           desc: '校园筛查',
  //           jurisdiction: [6, 10, 9]
  //         }
  //       }
  //     }
  //   ]
  // }
  // {
  //   path: '/management/change/password',
  //   name: 'managementChangePassword',
  //   component: { render: h => h('router-view') },
  //   meta: {
  //     nav: {
  //       title: '设置',
  //       desc: '设置',
  //       icon: 'el-icon-s-tools'
  //     }
  //   },
  //   children: [
  //     {
  //       path: '/management/change/password',
  //       redirect: '/change/password',
  //       meta: {
  //         nav: {
  //           title: '修改密码',
  //           desc: '修改密码'
  //         }
  //       }
  //     }
  //   ]
  // }
  // 大数据
  {
    path: '/detection/home/show',
    component: { render: h => h('router-view') },
    meta: {
      nav: {
        title: '校园筛查',
        desc: '校园筛查',
        icon: 'el-icon-school',
        jurisdiction: [6, 10, 9]
      }
    },
    children: [
      {
        path: '/detection/analysisChart/show',
        component: { render: h => h('router-view') },
        meta: {
          nav: {
            title: '报表中心',
            desc: '报表中心',
            jurisdiction: [6, 10, 9]
          }
        },
        children: [
          {
            path: '/detection/analysisChart',
            component: AnalysisChart,
            name: 'AnalysisChart',
            meta: {
              nav: {
                title: '分析图表',
                desc: '分析图表',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/schoolReport',
            component: SchoolReport,
            meta: {
              nav: {
                desc: '学校报表',
                title: '学校报表',
                jurisdiction: [9]
              }
            }
          }
        ]
      },
      {
        path: '/detection/screeningListQuery/show',
        component: { render: h => h('router-view') },
        meta: {
          nav: {
            jurisdiction: [6, 10, 9],
            title: '筛查计划管理',
            desc: '筛查计划管理'
          }
        },
        children: [
          {
            path: '/detection/screeningListQuery',
            component: ScreeningListQuery,
            name: 'screeningListQuery',
            meta: {
              nav: {
                title: '筛查列表查询',
                desc: '筛查列表查询',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/barCode',
            component: barCode,
            meta: {
              keepAlive: true,
              nav: {
                desc: '打印二维码',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/screeningPlanList',
            name: 'detectionScreeningPlanList',
            component: ScreeningPlanList,
            meta: {
              nav: {
                title: '筛查计划查询',
                desc: '筛查计划查询',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/screeningPlanAdd',
            component: ScreeningPlanAdd,
            meta: {
              keepAlive: true,
              nav: {
                desc: '新增计划',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/screeningPlanEdit',
            component: ScreeningPlanEdit,
            meta: {
              keepAlive: true,
              nav: {
                jurisdiction: [6, 10, 9],
                desc: '修改信息'
              }
            }
          },
          {
            path: '/detection/schoolList',
            component: SchoolList,
            meta: {
              nav: {
                title: '导入学生',
                desc: '导入学生',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/student',
            component: Student,
            meta: {
              nav: {
                desc: '学生导入',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/class',
            component: Class,
            meta: {
              nav: {
                desc: '导入学生',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/importStudent',
            component: ImportStudent,
            meta: {
              keepAlive: true,
              nav: {
                desc: '批量导入学生',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/management/importScreeningData',
            name: 'managementImportScreeningData',
            component: importScreeningData,
            meta: {
              nav: {
                jurisdiction: [6, 10, 9],
                desc: '导入数据'
              }
            }
          },
          {
            path: '/management/importKaramayEdition',
            name: 'managementImportKaramayEdition',
            component: importKaramayEdition,
            meta: {
              nav: {
                jurisdiction: [6, 10, 9],
                desc: '导入数据'
              }
            }
          },
          {
            path: '/detection/recycle',
            component: Recycle,
            meta: {
              keepAlive: true,
              nav: {
                desc: '回收站',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/addStudentInfo',
            component: AddStudentInfo,
            meta: {
              keepAlive: true,
              nav: {
                desc: '新增学生',
                jurisdiction: [6, 10, 9]
              }
            }
          },
          {
            path: '/detection/editStudentInfo',
            component: EditStudentInfo,
            meta: {
              keepAlive: true,
              nav: {
                desc: '修改信息',
                jurisdiction: [6, 10, 9]
              }
            }
          }
        ]
      },
      {
        path: '/detection/schoolManagement/show',
        component: { render: h => h('router-view') },
        meta: {
          nav: {
            title: '筛查前设置',
            desc: '筛查前设置',
            jurisdiction: [9, 10]
          }
        },
        children: [
          {
            path: '/detection/schoolManagement',
            component: SchoolManagement,
            meta: {
              nav: {
                title: '学校管理',
                desc: '学校管理',
                jurisdiction: [9]
              }
            }
          },
          {
            path: '/detection/schoolAdd',
            component: SchoolAdd,
            meta: {
              keepAlive: true,
              nav: {
                jurisdiction: [9],
                desc: '新增学校'
              }
            }
          },
          {
            path: '/detection/schoolEdit',
            component: SchoolEdit,
            meta: {
              keepAlive: true,
              nav: {
                desc: '修改信息',
                jurisdiction: [9]
              }
            }
          },
          {
            path: '/detection/accountManagement',
            component: AccountManagement,
            meta: {
              keepAlive: true,
              nav: {
                title: '账户管理',
                desc: '账户管理',
                jurisdiction: [9]
              }
            }
          },
          {
            path: '/detection/accountAdd',
            component: AccountAdd,
            meta: {
              keepAlive: true,
              nav: {
                desc: '新增账户',
                jurisdiction: [9]
              }
            }
          },
          {
            path: '/detection/accountEdit',
            component: AccountEdit,
            meta: {
              keepAlive: true,
              nav: {
                desc: '修改信息',
                jurisdiction: [9]
              }
            }
          },
          {
            path: '/management/school/set',
            name: 'managementSchoolSet',
            component: schoolSet,
            meta: {
              nav: {
                title: '机构管理',
                desc: '机构管理',
                jurisdiction: [10]
              }
            }
          }
        ]
      }
    ]
  },
  {
    path: '/detection/monitoringKanban/show',
    component: { render: h => h('router-view') },
    meta: {
      nav: {
        title: '动态监测',
        desc: '动态监测',
        icon: 'el-icon-data-line',
        jurisdiction: [7, 6, 10, 9]
      }
    },
    children: [
      {
        path: '/detection/monitoringKanban',
        component: MonitoringKanban,
        name: 'MonitoringKanban',
        meta: {
          nav: {
            title: '监测看板',
            desc: '监测看板',
            jurisdiction: [7, 6, 10, 9]
          }
        }
      },
      {
        path: '/detection/monitoringManagement',
        component: MonitoringManagement,
        name: 'MonitoringManagement',
        meta: {
          nav: {
            title: '监测管理',
            desc: '监测管理',
            jurisdiction: [7, 6, 10, 9]
          }
        }
      }
      // ,
      // {
      //   path: '/detection/monitoringDynaport',
      //   component: MonitoringDynaport,
      //   name: 'MonitoringDynaport',
      //   meta: {
      //     nav: {
      //       title: '动态报告',
      //       desc: '动态报告',
      //       jurisdiction: [7, 6, 10, 9]
      //     }
      //   }
      // }
    ]
  }
]
