<template>
  <el-form-item class="button-section" style="text-align: right; justify-content: flex-end">
    <!-- <el-button type="primary" @click="saveData" :disabled="disabled ===0" v-loading.fullscreen.lock="fullscreenLoading"> -->
    <el-button type="primary" @click="saveData">
      {{ saveText }}
    </el-button>
    <el-button @click="back">
      {{ cancelText }}
    </el-button>
  </el-form-item>
</template>

<script>
export default {
  name: 'form-button-group',
  data () {
    return {
      // disabled: 1,
      // fullsModifyplan: false
    }
  },
  props: {
    saveText: {
      type: String,
      default: '新增'
    },
    cancelText: {
      type: String,
      default: '取消'
    }
  },
  methods: {
    saveData () {
      this.$emit('saveData')
    },
    back () {
      this.$router.back()
    }
  }
}
</script>

<style scoped>

</style>
