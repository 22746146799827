<template>
  <el-pagination
    background
    @current-change="currentChange"
    :layout="layout"
    :page-sizes="pageSizes"
    :page-size="pageSize"
    @size-change="sizeChange"
    :page-count="total"
    :current-page="currentPage"
    style="flex: 1; text-align: center"
  >
  </el-pagination>
</template>

<script>
export default {
  name: 'pagination',
  props: {
    // 每页显示条目个数
    pageSize: {
      type: Number,
      default: 8
    },
    // 总条目数
    total: {
      type: Number,
      default: 0
    },
    // 当前页数
    currentPage: {
      type: Number,
      default: 1
    },
    // 组件布局
    layout: {
      type: String,
      default: 'sizes, prev, pager, next, jumper'
    },
    // 每页显示个数选择器的选项设置
    pageSizes: {
      type: Array,
      default () {
        return [8, 100, 200, 300, 400]
      }
    }
  },
  methods: {
    currentChange (val) {
      this.$emit('currentChange', val)
    },
    sizeChange (val) {
      this.$emit('sizeChange', val)
    }
  }
}
</script>

<style scoped>

</style>
