<template>
  <div class="container" ref="BasicLayoutHeader">
    <div class="header-router-section">
      <div class="into">
        <i :class="!isCollapse ? 'el-icon-s-fold': 'el-icon-s-unfold'" @click="switchover"></i>
      </div>
      <div class="router-name" v-for="(item, index) in routerNameArr" :key="item.value + index">
        <p v-if="!item.key" class="router-title">
          {{item.value}}<span>/</span>
        </p>
        <p v-if="item.key" class="router-title-show">
           {{ item.value}}
        </p>
      </div>
    </div>
    <div class="user-section">
      <el-image>
        <div slot="error" class="image-slot">
          <i v-if="!userNameOne" class="el-icon-user-solid"></i>
          <p v-if="userNameOne"  class="user-title">{{userNameOne}}</p>
        </div>
      </el-image>
      <p class="user-name">{{userName}}</p>
      <span>|</span>
      <el-dropdown>
        <span class="el-dropdown-link">
          设置<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>
            <router-link :to="{name: 'ChangePassword'}">
              <el-button type="text">修改密码</el-button>
            </router-link>
          </el-dropdown-item>
          <el-dropdown-item>
            <el-button style="width: 100%; text-align: left" @click="quit" type="text">退出</el-button>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import storage from '@/globals/storage/index.js'

export default {
  data () {
    return {
      userNameOne: null,
      lock: false,
      routerNameArr: [],
      userName: ''
    }
  },
  created () {
    this.getRouter()
    this.getUserInfo()
    // console.log('1', window.sessionStorage.getItem('uid'))
    // if (!window.sessionStorage.getItem('uid') && !window.sessionStorage.getItem('loginKey')) {
    //   this.quit()
    //   console.log('111')
    // }
  },
  mounted () {
    this.editResize()
    window.addEventListener('resize', this.editResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.editResize)
  },
  props: {
    isCollapse: Boolean
  },
  watch: {
    $route: 'getRouter'
  },
  methods: {
    quit () {
      storage.clear()
      location.reload()
    },
    getUserInfo () {
      this.userName = storage.get('sysName')
      this.userNameOne = this.userName.substring(0, 1)
    },
    editResize () {
      if (this.lock) return
      this.lock = true
      setTimeout(() => {
        if (this.$refs.BasicLayoutHeader.clientWidth < 300 && !this.isCollapse) {
          this.switchover()
        }
        this.lock = false
      }, 1000)
    },
    getRouter () {
      const arr = []
      this.$route.matched.forEach((item, index) => {
        if (item.meta.nav) {
          let value = ''
          if (item.meta.nav.desc) value = item.meta.nav.desc
          arr.push({ value, key: index === this.$route.matched.length - 1 })
        }
      })
      this.routerNameArr = arr
    },
    switchover () {
      this.$emit('update:isCollapse', (!this.isCollapse))
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  height: 98%;
  color: #333;
  box-shadow: 0px 0px 10px 0px rgba(4, 30, 89, 0.25);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 12px;
  .header-router-section{
    display: flex;
    align-items: center;
    .into{
      padding: 20px;
      font-size: 18px;
      color: #fff;
      i{
        &:hover{
          cursor:pointer;
        }
      }
    }
    .router-name{
      font-size: 16px;
      color: #e5e5e5;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 300;
      white-space:nowrap;
      .router-title{
        span{
          display: inline-block;
          margin: 0 3px;
        }
      }
      .router-title-show{
        color: #fff;
      }
    }
  }
  .user-section{
    display: flex;
    align-items: center;
    padding-right: 22px;
    color: #fff;
    .el-image{
      width: 30px;
      height: 30px;
      background-color: rgb(250, 100, 0);
      border-radius: 50%;
      margin: 0 10px;
      text-align: center;
      line-height: 30px;
      flex-shrink: 0;
    }
    .user-name{
      font-size: 16px;
      font-weight: 500;
      white-space:nowrap;
    }
    span{
      display: inline-block;
      margin: 0 20px;
    }
    .el-dropdown{
      color: #fff;
      flex-shrink: 0;
      span{
        margin: 0;
        padding: 0;
      }
      .el-dropdown-menu{
        .el-button{
          width: 100% !important;
        }
      }
    }
    .el-button{
      color: #fff;
      width: 100% !important;
    }
  }
}
</style>
