import Vue from 'vue'
import VueRouter from 'vue-router'
import route from './router'
import NProgress from 'nprogress' // 加载条
import 'nprogress/nprogress.css' // 加载条样式
import storage from '@/globals/storage/index.js'
import { Message } from 'element-ui'
Vue.use(VueRouter)
const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: route
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login' && to.name !== 'ChangePassword') {
    const loginKey = storage.get('loginKey')
    const roleType = Number(storage.get('roleType'))
    const uid = storage.get('uid')
    const sysName = storage.get('sysName')
    if (!loginKey || !roleType || !uid || !sysName) {
      Message.error('你的登录已失效，请重新登录')
      storage.clear()
      NProgress.start()
      return next({
        name: 'login'
      })
    }
    if (!to.meta.nav.jurisdiction.some(item => item === roleType)) {
      Message.error('你的权限不可登录此页面')
      storage.clear()
      NProgress.start()
      return next({
        name: 'login'
      })
    }
  }
  NProgress.start()
  next()
})

// eslint-disable-next-line
router.afterEach((to, from) => {
  NProgress.done()
})

export default router
