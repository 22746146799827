<template>
  <div class="container">
    <div class="soderbar-header">
      <img src="@/assets/management/logo.png" alt="" class="logo">
      <p v-if="!isCollapse" class="logo-title">视力健康管理系统</p>
    </div>
    <div class="el-menu_container">
      <el-menu v-for="item in routerList" :key="item.path" :unique-opened="true"  router class="el-menu-vertical-demo" :collapseTransition="false" :collapse="isCollapse">
         <el-submenu :index="item.path" v-if="item.list">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title">{{item.title}}</span>
          </template>
          <div v-for="data in item.list" :key="data.path + data.name" >
            <el-menu-item-group v-if="!data.list">
              <el-menu-item :index="data.path">{{data.title}}</el-menu-item>
            </el-menu-item-group>
            <el-submenu :index="data.path" v-if="data.list">
              <span slot="title">{{data.title}}</span>
              <el-menu-item  v-for="newData in data.list" :key="newData.path + newData.name" :index="newData.path">{{newData.title}}</el-menu-item>
            </el-submenu>
          </div>

        </el-submenu>
        <el-menu-item :index="item.path"  v-if="!item.list">
          <i :class="item.icon"></i>
          <span slot="title">{{item.title}}</span>
        </el-menu-item>
      </el-menu>
    </div>
    <p v-if="!isCollapse" class="roleTypeText-title">
      {{roleTypeText}}
    </p>
  </div>
</template>
<script>
import router from '@/router/managementRouter'
import storage from '@/globals/storage/index.js'

export default {
  data () {
    return {
      roleTypeText: '',
      routerList: []
    }
  },
  props: {
    isCollapse: {
      type: Boolean,
      default: true
    }
  },
  created () {
    this.routerList = this.getRouterSetData(router)
    console.log('this.routerList', this.routerList)
    const roleType = Number(storage.get('roleType'))
    switch (roleType) {
      case 1:
        this.roleTypeText = '超级管理员端'
        break
      case 10:
        this.roleTypeText = '学校端'
        break
      case 99:
        this.roleTypeText = '政府端'
        break
      case 7:
        this.roleTypeText = '教师端'
        break
      case 9:
        this.roleTypeText = '机构端'
        break
    }
  },
  methods: {
    getRouterSetData (data) {
      const newData = []
      data.forEach(item => {
        const roleType = Number(storage.get('roleType'))
        if (item.children && !item.meta.nav.title) return
        if (item.children || item.meta.nav.jurisdiction) {
          if (!item.meta.nav.jurisdiction.some(item => item === roleType)) return
        }
        if (item.children) {
          item.childrens = this.getRouterSetData(item.children)
          newData.push({
            title: item.meta.nav.title,
            name: item.name,
            path: item.path,
            list: item.childrens,
            icon: item.meta.nav.icon
          })
        } else if (item.meta.nav.title) {
          // console.log(item.meta.nav)
          newData.push({
            title: item.meta.nav.title,
            name: item.name,
            path: item.path,
            icon: item.meta.nav.icon
          })
        }
      })
      return newData
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  overflow-y: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
.soderbar-header{
  width: 100%;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  .logo{
    width: 38px;
    height: 29px;
    margin: 0 11px;
  }
  .logo-title{
    font-size: 16px;
    color: #fff;
    font-weight: 500;
  }
}
/deep/.el-menu{
  border: none !important;
  background-color: rgba(255,255,255,0) !important;
  .el-submenu__title{
    color: #fff !important;
    &:hover{
      background-color: rgba(255,255,255,0) !important;
      color: #05BAEC !important;
    }
  }
  .el-menu-item{
    color: #fff !important;
    background-color: rgba(255,255,255,0) !important;
    &:hover{
      color: #05BAEC !important;
    }
  }
}
/deep/.el-menu-item-group__title{
  display: none !important;
}
/deep/ .el-menu--popup-right-start{
  background-color: #fff !important;
  .el-menu-item{
    color: #333 !important;
  }
}
.el-menu_container{
  width: 100%;
  // flex-shrink: 0;
  flex-grow: 2;
  align-self: flex-start;
  align-items: flex-start;
  // flex: 1;
}
.roleTypeText-title{
  width: 100%;
  text-align: center;
  color: #f5f5f5;
  // align-self: flex-end;
  line-height: 30px;
}
</style>
